import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/userEffects/Editor';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class TrainerUserEffectsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userEffect: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userEffect, location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-user-effects-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userEffect}
                >
                    {userEffect && userEffect.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Edytuj efekt"
                            subHeadline={getFormattedDate(userEffect.data.createdAt)}
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={userEffect.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </Page>
            </StyledComponent>
        );
    }
}