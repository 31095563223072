import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { 
    TRAINER_ADEPTS_MANAGE,
    TRAINER_USER_EFFECT_MANAGE,
} from 'Consts/routes';
import { 
    API_RESOURCE_USER_EFFECT,
} from 'Consts/apiResources';
import { ENDPOINT_TRAINER_FILE_PRESIGN } from 'Consts/api';
import { 
    TYPES,
    TYPE_SINGLE_PHOTO,
    TYPE_TWO_PHOTOS,
} from 'Consts/userEffects';

import { withVariables } from 'Utils/string';
import { getTypeLabel } from 'Utils/userEffect';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_USER_EFFECTS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerUserEffectEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedQuery: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    dataToFormState = data => {
        return {
            ...data,
            type: data.type
                ? { value: data.type, label: getTypeLabel(data).label }
                : null,
        };
    }

    formStateToData = formState => {
        const { data } = this.props;

        return {
            ...formState,
            enabled: formState.enabled || false,
            promoted: formState.promoted || false,
            photoId: formState.photo ? formState.photo.id : undefined,
            photoBeforeId: formState.photoBefore ? formState.photoBefore.id : undefined,
            photoAfterId: formState.photoAfter ? formState.photoAfter.id : undefined,
            userId: data?.user?.id || undefined,
            type: fromSelectObject(formState.type),
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(`${errorMessage}`);
                    });
                });

                throw error;
            })
    }

    onCreate = formState => {
        const { actions, history, predefinedQuery } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
            ...predefinedQuery
        }).then(response => {
            history.push(
                withVariables(
                    TRAINER_USER_EFFECT_MANAGE.path, 
                    { id: response.payload[API_RESOURCE_USER_EFFECT].id }, 
                    { tab: TAB_USER_EFFECTS }
                )
            );
        })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(`${errorMessage}`);
                    });
                });

                throw error;
            });
    }

    render() {
        const { location, history, data, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="trainer-user-effect-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń efekt',
                        subtitle: 'Efekt zostanie usunięty',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                return history.push(
                                    withVariables(
                                        TRAINER_ADEPTS_MANAGE.path, 
                                        { id: data.user.id }, 
                                        { tab: TAB_USER_EFFECTS }
                                    )
                                );
                            }),
                            children: 'Usuń',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'type',
                            label: 'Typ',
                            options: TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                            required: true,
                        }, {
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'effect',
                            label: 'Osiągnięty efekt',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'effectDetailed',
                            label: 'Szczegóły efektu',
                        }, {
                            type: 'input',
                            name: 'effectHeadline',
                            label: 'Nagłówek (ważne przy efekcie bez zdjęć)',
                        }, {
                            type: 'input',
                            name: 'effectSubheadline',
                            label: 'Podtytuł (ważne przy efekcie bez zdjęć)',
                        }, {
                            type: 'textarea',
                            name: 'text',
                            label: 'Tekst',
                            required: true,
                        }, {
                            type: 'switch',
                            name: 'enabled',
                            label: 'Włączony/wyłączony',
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz adepta',
                        }, {
                            type: 'input',
                            name: 'commentAdditional',
                            label: 'Dodatkowa informacja do komentarza'
                        }, {
                            type: 'input',
                            name: 'weightLoss',
                            label: 'Różnica w wadze',
                        }, {
                            type: 'input',
                            name: 'metabolicAge',
                            label: 'Różnica w wieku metabolicznym',
                        }, {
                            type: 'input',
                            name: 'beltLoss',
                            label: 'Różnica w pasie',
                        }, {
                            type: 'input',
                            name: 'fatTissueLoss',
                            label: 'Różnica w tkance tłuszczowej'
                        }, {
                            type: 'switch',
                            name: 'promoted',
                            label: 'Widoczność na stronie głównej',
                        }, {
                            type: 's3FileUpload',
                            name: 'photo',
                            label: 'Zdjęcie',
                            isVisible: formState.type?.value === TYPE_SINGLE_PHOTO,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoBefore',
                            label: 'Zdjęcie przed',
                            isVisible: formState.type?.value === TYPE_TWO_PHOTOS,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'photoAfter',
                            label: 'Zdjęcie po',
                            isVisible: formState.type?.value === TYPE_TWO_PHOTOS,
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                        .replace('{type}', 'userEffect'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}